<template>
  <div>
    <a-card title="半成品及成品管理">
      <a-row :gutter="16">
        <div style="margin-bottom: 12px; float: right">
          <a-button type="primary" icon="plus" style="margin: 0 8px" @click="openFormModal(form)">新增</a-button>
        </div>
      </a-row>

      <a-row style="margin-top: 12px; display: flex">
        <div class="leftPro" style="width: 21%"><!--  @scroll="onScroll" -->
          <ul  >
            <li class="title">产品列表</li>
            <li>
              <a-input-search
                      placeholder="id或名称"
                      style="width: 230px; margin: 0 10px 20px"
                      @search="onSearch"
              />
            </li>
            <li
                    class="item"
                    v-for="(val, index) in productList"
                    @click="getBatchList(val.id, index)"
                    :key="index"
                    :style="index === productIndex ? 'color: #40a9ff' : ''"
            >
              <p style="margin: 0">{{ val.product_name }} ({{ val.product_id }})</p>
            </li
            >
          </ul>
        </div>

        <ul class="leftPro" style="margin: 0 20px">
          <li class="title">批次列表</li>
          <li>
            <a-input-search
                    placeholder="请输入批次"
                    style="width: 140px; margin: 0 10px 20px"
                    @search="onSearch1"
            />
          </li>
          <li
                  class="item"
                  v-for="(val, index) in BatchList.pici"
                  @click="getResultList(BatchList.product_id, index, val)"
                  :key="index"
                  :style="index === batchIndex ? 'color: #40a9ff' : ''"
          >{{ val.batch }}</li
          >
        </ul>
        <a-table
                style="flex: 1"
                size="small"
                :pagination="pagination"
                :columns="columns"
                :dataSource="items"
                rowKey="id"
                :loading="loading"
                @change="tableChange"
        >
          <div slot="is_active" slot-scope="value">
            <a-tag :color="value ? 'green' : 'red'">{{ value ? "激活" : "冻结" }}</a-tag>
          </div>
          <div slot="result" slot-scope="value">
            <span>{{ value && value.join(',') }}</span>
          </div>
          <div slot="action" slot-scope="value, item">
            <a-button-group>
              <a-button icon="eye" size="small" @click="openFormModal2(item)">查看</a-button>
              <a-button icon="edit" size="small" @click="openFormModal1(item)">编辑</a-button>
              <a-popconfirm title="确定删除吗" @confirm="destroy(item.id)">
                <a-button type="danger" icon="delete" size="small">删除</a-button>
              </a-popconfirm>
            </a-button-group>
          </div>
        </a-table>
      </a-row>
    </a-card>
    <form-modal
            :nowId="nowId"
            :BatchList="BatchList"
            v-model="visible"
            :form="targetItem"
            :clientsClassificationOptions="clientsClassificationItems"
            @create="create"
            @update="update"
    />
    <form-modal1
            v-model="visible1"
            :BatchList="BatchList"
            :form="targetItem1"
            :reNumber="reNumber"
            :clientsClassificationOptions="clientsClassificationItems1"
            @create="create"
            @update="update"
    />
    <form-modal2
            v-model="visible2"
            :form="targetItem2"
            :clientsClassificationOptions="clientsClassificationItems2"
            @create="create"
            @update="update"
    />
    <a-modal v-model="importLoading" :footer="null" :maskClosable="false" :closable="false">
      <div><a-spin style="margin-right: 12px" />正在导入中, 请等待...</div>
    </a-modal>
  </div>
</template>

<script>
  import { fromProductList, traceabilityList, traceabilityDel, tracePici } from "@/api/from";
  function deepClone(obj) {
    if (obj === null || typeof obj !== 'object') {
      return obj;
    }

    let clonedObj = Array.isArray(obj) ? [] : {};

    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        clonedObj[key] = deepClone(obj[key]);
      }
    }

    return clonedObj;
  }
  export default {
    name: "Warehouse",
    components: {
      FormModal: () => import("./FormModal.vue"),
      FormModal1: () => import("./FormModal1.vue"),
      FormModal2: () => import("./FormModal2.vue")
    },
    data() {
      return {
        nowId: '',
        nowPici: '',
        reNumber: [],
        productList: [],
        productIndex: 0,
        batchIndex: 0,
        BatchList: [],
        columns: [
          {
            title: "检验标题",
            dataIndex: "title"
          },
          {
            title: "创建时间",
            dataIndex: "create_at"
          },
          {
            title: "批次",
            dataIndex: "date"
          },
          {
            title: "编号",
            dataIndex: "number"
          },
          {
            title: "描述",
            scopedSlots: { customRender: "result" },
            dataIndex: "result"
          },
          {
            title: "操作",
            dataIndex: "action",
            scopedSlots: { customRender: "action" },
            width: "156px",
          },
        ],
        pagination: { current: 1, total: 0, pageSize: 10 },
        searchForm: { product_id: "", pici: '', page: 1, page_size: 10 },
        loading: false,
        items: [],
        clientsClassificationItems: [],
        clientsClassificationItems1: [],
        clientsClassificationItems2: [],
        visible: false,
        visible1: false,
        visible2: false,
        targetItem: { data: {} },
        targetItem1: { data: {} },
        targetItem2: { data: {} },
        form: { data: {} },
        importLoading: false,
        page:1,
        page_size:20
      };
    },
    async created() {
      this.getRefresh()
    },
    methods: {
      async getRefresh() {
        let res = await fromProductList({ page: 1, page_size: 9999 });
        this.productList = res.data;
        this.nowId = res.data[0].id
        this.getBatchList(res.data[0].id, 0)
      },
      async getProductList(){
       let res = await fromProductList({ page: this.page, page_size: this.page_size });
       this.productList =[...this.productList,...res.data]
      },
      onScroll(e){
        const bottomDistance = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight;
        const totalPage = Math.ceil(this.productList.length / this.page_size);
        if (bottomDistance < 50 &&this.page < totalPage) {
          this.page++;
          this.getProductList();
        }
      },
      openFormModal2(item) {
        item.result = item.result !== null ? item.result : []
        if (!item.id) {
          this.targetItem = deepClone(item)
        } else {
          let arr = item.image && item.image.map((item, index) => {
            if(!item.status){
              const n = item.split('/')
              return {
                uid: index + '',
                name: n[n.length-1],
                status: 'done',
                url: item,
              }
            }else {
              return item
            }
          });
          item.image = arr
          this.targetItem2 = deepClone(item)
        }
        this.visible2 = true;
      },
      getResultList(id, num, pici) {
        this.batchIndex = num;
        this.nowId = id;
        this.nowPici = pici.batch
        this.list(id, pici.batch);
      },
      getBatchList(val, num) {
        this.productIndex = num;
        this.batchListInfo(val);
      },
      async batchListInfo(id) {
        let res1 = await tracePici({ product_id: id });
        this.BatchList = res1.data;
        this.nowId = id;
        this.nowPici = res1.data.pici[0].batch
        this.list(id, res1.data.pici[0].batch)
      },
      onSearch(val) {
        if (val !== undefined) {
          this.productIndex = null;
          this.getProductInfo(val)
        }
      },
      async getProductInfo(val) {
        this.nowId = val;
        let res = await fromProductList({ product_kw: val });
        this.productList = res.data;
      },
      onSearch1(val) {
        if (val !== undefined) {
          this.batchIndex = null;
          let arr = this.BatchList.pici.filter((date) => date.batch.includes(val));
          this.BatchList.pici = arr;
        } else {
          this.batchListInfo('')
        }
      },
      list(id, pici) {
        this.searchForm.product_id = id;
        this.searchForm.pici = pici;
        this.loading = true;
        traceabilityList(this.searchForm)
                .then((data) => {
                  this.pagination.total = data.count;
                  this.items = data.data;
                })
                .finally(() => {
                  this.loading = false;
                });
      },
      async create() {
        this.list(this.nowId, this.nowPici);
        this.getRefresh()
      },
      update() {
        this.list(this.nowId, this.nowPici);
      },
      search() {
        this.pagination.current = 1;
        this.searchForm.page = 1;
        this.list();
      },
      openFormModal(item) {
        item.result = item.result !== null ? item.result : []
        this.form.product_id = this.nowId
        this.targetItem = deepClone(item)
        this.visible = true;
      },
      openFormModal1(item) {
        item.result = item.result !== null ? item.result : []
        let arr = this.BatchList.pici.filter(obj => obj.batch == item.date).map(obj => obj.number).flat();
        this.reNumber = arr
        this.targetItem = deepClone(item)
        if (!item.id) {
          this.targetItem = deepClone(item)
        } else {
          let arr1 = item.image && item.image.map((item, index) => {
            if(!item.status){
              const n = item.split('/')
              return {
                uid: index + '',
                name: n[n.length-1],
                status: 'done',
                url: item,
              }
            }else {
              return item
            }
          });
          item.image = arr1
          this.targetItem1 = deepClone(item)
        }
        this.visible1 = true;
      },
      destroy(id) {
        traceabilityDel({ id: id })
                .then((data) => {
                  this.$message.success("删除成功");
                  this.list(this.nowId, this.nowPici);
                })
                .finally(() => {
                  this.loading = false;
                });
      },
      tableChange(pagination, filters, sorter) {
        this.searchForm.page = pagination.current;
        this.pagination.current = pagination.current;
        this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
        this.list(this.nowId, this.nowPici);
      },
    },
    mounted() {
      // this.initialize();
    },
  };
</script>

<style scoped lang="less">
  ul,li{
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .leftPro {
    overflow-y: auto;
    overflow-x: hidden;
    height: 77vh;
    background: #eee;
    .title {
      font-weight: bold;
      text-align: center;
      line-height: 60px;
    }
    .item {
      width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin: 0 5% 20px 5%;
      cursor: pointer;
    }
  }
</style>
